import React from 'react';
import { Paper, Typography, Button } from '@material-ui/core';

import styled from 'styled-components';
import { spacing } from '@material-ui/system';

import { graphql, Link } from 'gatsby';
import Img from 'gatsby-image';

import { useAuthentication } from '../components/Authentication';

import { SignUpForm } from '../components/Forms';
import { FixedImage } from '../components/Trainings/types';

interface Props {
  data: {
    file: FixedImage;
  };
}

export default function SignUp({ data }: Props) {
  const { signUp } = useAuthentication();

  return (
    <Container>
      <StyledPaper p={9}>
        <LogoContainer>
          <Logo fixed={data.file.childImageSharp.fixed} ml="38px" />
        </LogoContainer>
        <Section>
          <Typography gutterBottom variant="h5">
            {'Créez votre compte personnel'}
          </Typography>
          <SignUpForm onSubmit={signUp} />
          <StyledButton component={Link} mt={5} to="/login" variant="outlined">
            {'Vous avez déjà un compte ?'}
          </StyledButton>
        </Section>
      </StyledPaper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
  align-items: center;
`;

const StyledPaper = styled(props => <Paper {...props} />)`
  ${spacing}
  display: grid;
  grid-row-gap: 72px;
  grid-template-columns: 360px;
`;

const StyledButton = styled(props => <Button {...props} />)`
  ${spacing}
`;

const LogoContainer = styled.div`
  place-self: center;
`;

const Logo = styled(Img)`
  ${spacing}
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
`;

export const query = graphql`
  query {
    file(name: { regex: "/logo/" }) {
      childImageSharp {
        fixed(width: 124) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;
